<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo />
        <!-- End Header Area -->
        <!-- Start Slider Area  -->
        <div class="rn-section-gapDoubleTop bg_color--1">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="section-title text-center mb--20 mb_sm--0">
                            <h2 class="heading-title" align="center">From Venue</h2>
                            <br />
                            <div class="about-area">
                                <div class="about-wrapper">
                                  <BusPeopleFrom>
                                        <img slot="thum-img"
                                             class="w-50"
                                             src="../../assets/images/shuttle/strip.png"
                                             alt="About Images" />
                                    </BusPeopleFrom>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
      
        <FooterTwo />
    </div>
</template>

<script>
    import HeaderOnePageTwo from "../../components/header/HeaderOnePageTwo";
    import InvitationDetails from "../../components/invitation/InvitationDetails";
    import PortfolioTwo from "../../components/portfolio/PortfolioFour";
    import Frame from "../../components/shuttle/Frame";
    import BusPeopleFrom from "../../components/shuttle/BusPeopleFrom";
    import FooterTwo from "../../components/footer/FooterTwo";

    export default {
        components: {
            HeaderOnePageTwo,
            InvitationDetails,
            PortfolioTwo,
            Frame,
            FooterTwo,
            BusPeopleFrom
        }
    };
</script>

<style lang="scss">

    .feather-menu {
        color: #1d1d24;
    }
</style>
