<template>
  <v-container>
      <v-row align="center" class="row--35">
          <v-col lg="8" md="8" cols="12">
              <div class="about-inner inner">
                  <div class="section-title">
                      <p class="description" align="center">
                         
                          Please be aware that Ubers and taxis are quite difficult to obtain at the end of the evening, due to being approximately 15 minutes out of town and wrapping up at 11pm.  <br />

                          Our venue has recommended the use of a bus to transport everyone back into Geelong CBD where guests can either be dropped nearby their accommodation and Ubers/taxis are more readily available for those who need them. Also, if you're feeling in good form, there are options for kick-ons in town afterwards!  <br /> <br />

                          Final details on the bus times and pick-up/drop-off locations will be confirmed once we have an idea of numbers interested. Please note in your RSVP if you're keen on using this option!
                      </p>
                  </div>
              </div>
          </v-col>
          <v-col lg="4" md="4" cols="12" align="center">
              <div class="thumbnail">
                  <slot name="thum-img" align="center"></slot>
              </div>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
    export default {
          props: {
            message: {
              type: String,
              default: 'EMPTY MESSSAGE FIX ME'
            }
          },
    }
</script>